import React from "react"
import styled from "@emotion/styled"
import { Box, Flex } from "@theme-ui/components"

const TextContainer = styled.div`
  width: 100%;
  max-width: 850px;

  p {
    white-space: pre-wrap;
  }

  h3 {
    font-size: 10px;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 400;
  }
`

const OpenPosition = ({ data, padTop, padBottom }) => {
  return (
    <Box
      sx={{
        maxWidth: "1200px",
        justifyContent: "center",
        width: "100%",
      }}
      mx={"auto"}
      px={[3, "40px"]}
      pt={padTop ? [5, 6] : [2, 3]}
      pb={padBottom ? [5, 6] : [2, 3]}
    >
      <Flex
        sx={{
          flexDirection: "column",
          paddingTop: "20px",
          position: "relative",
          borderTop: "1px solid black",
        }}
      >
        <a href={`mailto:${data.email}?subject=${data.emailSubject}`}>
          <Box
            sx={{
              cursor: "pointer",
              fontFamily: "Formular",
              fontWeight: "700",
              textTransform: "uppercase",
              position: "absolute",
              top: 0,
              right: 0,
              fontSize: [0, 1],
              border: "none",
              backgroundColor: "black",
              padding: ["8px 20px", "10px 35px"],
              color: "white",
              a: {
                position: "absolute",
                display: "block",
                width: "100%",
                height: "100%",
                textDecoration: "none",
              },
            }}
            as="button"
          >
            Apply now
          </Box>
        </a>
        <Box as={"h2"} sx={{ fontWeight: "400" }}>
          {data.title}
        </Box>
        <Box
          sx={{
            width: "100%",
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          <TextContainer
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default OpenPosition
