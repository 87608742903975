import React from "react"
import styled from "@emotion/styled"
import {Link} from "gatsby"
import { Box, Flex } from "@theme-ui/components"

const StyledLink = styled(Link)`
    display: block;
    text-align: center;
    text-decoration: none;
    width: 100%;
    transition: all 0.2s ease;
    &:hover {
        background: black;
        color: white;
    }
`

const InlineMenu = ({ data, padBottom, padTop, prefix }) => {
    const {items, grid} = data
    return (
        <Box
        sx={{
            display: "grid",
            gridTemplateColumns: grid ? "repeat(2, 1fr)" : "repeat(1, 1fr)",
            gap: "1em",
            maxWidth: "960px",
            justifyContent: "center",
            width: "100%",
            flexWrap: "wrap",
        }}
        mx={"auto"}
        px={[3, "40px"]}
        pt={padTop ? [5, 6] : [2, 3]}
        pb={padBottom ? [5, 6] : [2, 3]}
        >
            {items.map((item, index) => {
                return (
                    <StyledLink
                        key={index}
                        to={`${prefix}${item.slug}/`}>
                        <Box
                            sx={{
                                padding: "0.75em",
                                fontSize: "1.25em",
                                border: "1px solid black",
                            }}
                        >
                            {item.title}
                        </Box>
                    </StyledLink>
                )
            })}
        </Box>
    )
}

export default InlineMenu
