import React from "react"
import styled from "@emotion/styled"
import {Link} from "gatsby"
import { Box, Flex } from "@theme-ui/components"

const CTA = ({ data, padBottom, padTop, prefix }) => {
    const {title, buttonLabel, link} = data 
  return (
    <Flex
      sx={{
        maxWidth: "1200px",
        justifyContent: "center",
        width: "100%",
      }}
      mx={"auto"}
      px={[3, "40px"]}
      pt={padTop ? [5, 6] : [2, 3]}
      pb={padBottom ? [5, 6] : [2, 3]}
    >
        <Flex
            sx={{
                fontSize: ["1rem", "1.25rem"],
                alignItems: "flex-end",
                flexGrow: "1",
                borderTop: "1px solid black",
                fontWeight: "300",
                fontFamily: "pragmatica-extended, sans-serif"
            }}
        >{title}</Flex>
        <Box
            href={link.slug}
            sx={{
                background: "black",
                color:"white",
                a:{
                    display: "block",
                    textDecoration:"none",
                    textTransform: "uppercase",
                    fontSize:"0.5rem",
                    padding:"1.5em 4em"
                }
            }}
        >
            <Link to={`${prefix}${link.slug}/`}>{buttonLabel}</Link>
        </Box>
    </Flex>
  )
}

export default CTA
