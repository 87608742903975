import React, { useEffect, useContext } from "react"
import { Flex, Box } from "@theme-ui/components"
import { Link, navigate, graphql, useStaticQuery } from "gatsby"

import Caret from "../assets/caret.png"
import BlondeTyped from "../assets/logo-typed-positive.svg"

const Footer = ({nav, prefix}) => {
  const now = new Date()

  return (
    <Flex mb={2} py={["50px", "150px"]}>
      <Flex
        m="auto"
        mx={"auto"}
        px={[3, "40px"]}
        sx={{
          maxWidth: "960px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Flex
          mb={"20px"}
          sx={{ width: "100%", alignItems: "center", cursor: "pointer" }}
        >
          <Flex
            sx={{ flex: 1, alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <Box
              sx={{
                height: "10px",
                img: { transform: "rotate(180deg)", height: "100%" },
              }}
              mr={2}
            >
              <img src={Caret} />
            </Box>

            <Box
              sx={{
                fontSize: "12px",
                color: "#2A2C3",
              }}
            >
              Back
            </Box>
          </Flex>
          <Box sx={{ height: "15px" }}>
            <img style={{ width: "auto" }} height="100%" src={BlondeTyped} />
          </Box>
        </Flex>
        <Flex
          sx={{
            flexDirection: ["column-reverse", "row"],
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Flex
            sx={{
              justifyContent: "space-between",
              flex: ["1", "unset"],
            }}
          >
            <Flex
              sx={{
                flex: ["1", "unset"],
                flexDirection: "column",
                justifyContent: ["flex-end", "space-between"],
              }}
            >
              <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                Copyright © {now.getFullYear()}
              </Box>
              <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                All Rights Reserved
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Flex
                px={[0, "20px"]}
                mb={[2, 0]}
                sx={{
                  fontSize: [1, 0],
                  textTransform: "uppercase",
                  maxWidth: "200px",
                  div: {
                    marginRight: "3px",
                    marginLeft: "3px",
                    textDecoration: "none",
                  },
                }}
              ></Flex>
              <Flex
                px={[0, "20px"]}
                mb={[2, 0]}
                sx={{
                  fontSize: [1, 0],
                  textTransform: "uppercase",
                  maxWidth: "200px",
                  a: {
                    marginRight: "3px",
                    marginLeft: "3px",
                    textDecoration: "none",
                  },
                }}
              >
                <a href="https://instagram.com/blonde___inc">Instagram</a>/
                <a href="https://vimeo.com/blondeinc">Vimeo</a>/
                <a href="https://linkedin.com/company/blondeinc">LinkedIn</a>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            sx={{
              flex: [1, "unset"],
              justifyContent: ["space-between", "flex-start"],
              flexDirection: ["row", "row"],
              fontWeight: "400",
              fontSize: [2, 1],
              textTransform: "uppercase",
              a: {
                marginBottom: [2, 0],
                textDecoration: "none",
                ":not(:last-of-type)": {
                  marginRight: "30px",
                },
              },
            }}
          >
            {nav.items.map((i,idx) => (
              <Link key={idx} to={`${prefix}${i.slug}/`}>{i.title}</Link>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default Footer
