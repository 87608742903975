import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Flex, Box } from "@theme-ui/components"

export default ({ data }) => {
  const containerStyles = {
    position: "relative",
    width: "100%",
    height: "100%",
    img: {
      width: "100%",
    },
  }

  const contentStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    color: data.lightText ? "white" : "darkest",
    h3: {
      fontFamily: "pragmatica-extended, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: ["10px", "26px"],
      lineHeight: "22px",
      textTransform: "uppercase",
      letterSpacing: ["3px", "6px"],
    },
  }

  return (
    <Box sx={containerStyles}>
      <GatsbyImage
        image={data.heroContent?.gatsbyImageData}
        alt={data.heroContent.title}
      />
      {data.displayTitle && (
        <Flex px={2} sx={contentStyles}>
          <h3>{data.displayTitle}</h3>
          {data.displaySubtitle && <p>{data.displaySubtitle}</p>}
        </Flex>
      )}
    </Box>
  )
}
