import React from "react"
import { Link } from "gatsby"
import { Box, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import Player from "./player"

const Gallery = ({ data, padBottom, padTop, prefix }) => {
  const { blocks, tag } = data
  return (
    <Flex
      sx={{
        maxWidth: "1200px",
        justifyContent: "center",
        width: "100%",
      }}
      mx={"auto"}
      px={[3, "40px"]}
      pt={padTop ? [5, 6] : [2, 3]}
      pb={padBottom ? [5, 6] : [2, 3]}
    >
      <Box
        sx={{
          display: "grid",
          gap: "1em",
          width: "100%",
          gridTemplateColumns:
            blocks.length > 2
              ? ["repeat(2, 1fr)", "repeat(3, 1fr)"]
              : ["repeat(2, 1fr)"],
        }}
      >
        {blocks.map((block, index) => {
          const link = () => {
            if (block.link.__typename === "ContentfulCase") {
              return `${prefix}cases/${block.link.slug}/`
            } else {
              return `${prefix}${block.link.slug}/`
            }
          }
          if (!block.vimeoId && block.link) {
            return (
              <Box key={index}>
                <Link style={{ textDecoration: "none" }} to={link()}>
                  <GatsbyImage image={block.asset.gatsbyImageData} />
                  <Box
                    mt={2}
                    sx={{
                      fontFamily: "pragmatica-extended, sans-serif",
                      textTransform: "uppercase",
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      {block.title}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: "300",
                      }}
                    >
                      {tag}
                    </Box>
                  </Box>
                </Link>
              </Box>
            )
          } else if (block.vimeoId) {
            return (
              <Flex sx={{ flexDirection: "column" }}>
                <Player
                  showPlay
                  id={block.link.slug}
                  thumbnail={block.asset}
                  aspectRatio={"2.39:1"}
                  vimeoId={block.vimeoId}
                />
                <Box
                  mt={2}
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    textTransform: "uppercase",
                  }}
                >
                  <Link style={{ textDecoration: "none" }} to={link()}>
                    <Box
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      {block.title}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: "300",
                      }}
                    >
                      {tag}
                    </Box>
                  </Link>
                </Box>
              </Flex>
            )
          } else {
            return (
              <Box>
                <GatsbyImage image={block.asset.gatsbyImageData} alt="" />
                <Box
                  mt={2}
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    textTransform: "uppercase",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    {block.title}
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "300",
                    }}
                  >
                    {tag}
                  </Box>
                </Box>
              </Box>
            )
          }
        })}
      </Box>
    </Flex>
  )
}

export default Gallery
