import styled from "@emotion/styled"
import { Box, Button, Flex, Text } from "@theme-ui/components"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import * as Yup from "yup"

const FormSchema = Yup.object().shape({
  position: Yup.string().required(),
  name: Yup.string().required(),
  email: Yup.string().required(),
  phone: Yup.string().required(),
  application: Yup.mixed().required(),
  portfolio: Yup.mixed().required(),
})

const TextContainer = styled.div`
  padding-left: 25px;
  flex-grow: 1;
  color: black;
  p {
    white-space: pre-wrap;
  }
`

const StyledFileInput = styled.input`
  cursor: pointer;
  width: 100%;
  display: inline-block;
  padding-left: 25px;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-top: 2px solid black;
  &::-webkit-file-upload-button {
    display: none;
  }
  &::before {
    content: "${(props) => props.label}";
    margin-right: 10px;
  }
`

const StyledInput = styled.input`
  padding-left: 25px;
  flex: 1;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-top: 2px solid black;
  background-color: transparent;
  outline: none;
  :first-child {
    border-top: none;
  }
  ::placeholder {
    color: black;
  }
`

const ProgramApplication = ({ data }) => {
  const [cookies, setCookies] = useCookies(["blonde-program-submitted"])
  const [loading, setLoading] = useState(false)
  const [submissionSuccess, setSubmissionSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      position: data.jobTitles[0],
      application: null,
      portfolio: null,
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      if (loading) return
      setLoading(true)
      const programForm = document.getElementById("blonde-program")
      const formData = new FormData(programForm)
      try {
        const response = await fetch("/", {
          method: "POST",
          body: formData,
        })
        if (response.status === 200) {
          setSubmissionSuccess(true)
        } else {
          console.log("error")
          console.log(response)
        }
      } catch (error) {
        alert(error)
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (submissionSuccess && !cookies["blonde-program-submitted"]) {
      setCookies("blonde-program-submitted", true, {
        path: "/",
        maxAge: 604800, // A week
      })
    } else if (cookies["blonde-program-submitted"]) {
      setSubmissionSuccess(true)
    }
  }, [submissionSuccess])

  const formStyle = {
    cursor: "pointer",
    flex: 1,
  }

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Flex
          id="blonde-program"
          as="form"
          name="blonde-program"
          data-netlify="true"
          onSubmit={formik.handleSubmit}
          mx={"auto"}
          sx={{
            color: "white",
            maxWidth: "960px",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <input type="hidden" name="form-name" value="blonde-program" />
          <Text
            py={15}
            sx={{
              fontWeight: "bold",
              paddingLeft: "25px",
              lineHeight: "1.2em",
              borderBottom: "2px solid black",
              fontSize: "28px",
              color: "black",
              width: "100%",
            }}
          >
            <h1>
              {data.heading}
              <Text
                sx={{
                  color: "black",
                  textAlign: "left",
                  display: ["block", "none", "none"],
                }}
              >
                Can only be done from desktop.
              </Text>
            </h1>
          </Text>
          <Box
            sx={{
              flexDirection: "column",
              flexGrow: "1",
              width: "100%",
              display: ["none", "flex", "flex"],
            }}
          >
            <TextContainer
              dangerouslySetInnerHTML={{
                __html: data.body.childMarkdownRemark.html,
              }}
            />
            {submissionSuccess ? (
              <Text
                sx={{ fontSize: "20px", color: "black" }}
                px={25}
                my={"15px"}
              >
                Your application has been received - thank you.
              </Text>
            ) : (
              <>
                <Flex
                  mt={"20px"}
                  sx={{
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {data.jobTitles?.map((jt, idx) => (
                    <Box
                      key={`${jt}-${idx}`}
                      onClick={() => formik.setFieldValue("position", jt)}
                      py={"20px"}
                      px={25}
                      sx={{
                        cursor: "pointer",
                        flex: 1,
                        borderTop: "2px solid black",
                        backgroundColor:
                          formik.values.position === jt ? "#069948" : "none",
                        color: "black",
                        ":last-child": {
                          marginBottom: "0",
                        },
                      }}
                    >
                      <input
                        name="position"
                        style={{ display: "none" }}
                        type="radio"
                        id={`${jt}-${idx}`}
                        checked={formik.values.position === jt}
                        onChange={formik.handleChange}
                        value={jt}
                      />
                      <label style={{ cursor: "pointer" }} for={`${jt}-${idx}`}>
                        {jt}
                      </label>
                    </Box>
                  ))}
                </Flex>
                <Box
                  sx={{
                    background: "#575757",
                    height: "30px",
                  }}
                />
                <Flex sx={{ width: "100%", flexDirection: "column" }}>
                  <StyledInput
                    name="name"
                    placeholder="Name:"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  <StyledInput
                    name="email"
                    placeholder="Email:"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <StyledInput
                    name="phone"
                    placeholder="Phone:"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  <Flex sx={formStyle}>
                    <StyledFileInput
                      label="Attach application:"
                      id="application"
                      name="application"
                      type="file"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "application",
                          e.currentTarget.files[0]
                        )
                      }
                    />
                  </Flex>
                  <Flex sx={formStyle}>
                    <StyledFileInput
                      label="Attach portfolio:"
                      name="portfolio"
                      id="portfolio"
                      type="file"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "portfolio",
                          e.currentTarget.files[0]
                        )
                      }
                    />
                  </Flex>
                  <Button
                    type="submit"
                    py="20px"
                    sx={{
                      ...formStyle,
                      marginRight: 0,
                      borderRadius: 0,
                      backgroundColor: "#575757",
                      color: "white",
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontWeight: "bold",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                  >
                    Apply Now
                  </Button>
                </Flex>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default ProgramApplication
