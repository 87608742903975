import React from "react"
import { Flex, Box } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const LogoModule = ({ data, padTop, padBottom }) => {
  return (
    <Box
      px={[3, 4, 4]}
      pt={padTop ? [5, 6] : [0]}
      mx={"auto"}
      sx={{ maxWidth: "1200px" }}
    >
      <Flex
        pb={padBottom ? [5, 6] : [0]}
        sx={{
          ml: "-20px",
          width: "calc(100% + 20px)",
          rowGap: "3",
          flexWrap: "wrap",
        }}
      >
        {data.logos.map((l) => (
          <Box
            sx={{
              width: ["25%", "25%", "12.5%"],
              pl: "40px",
            }}
            key={l.id}
          >
            <GatsbyImage image={l.gatsbyImageData} alt="" />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default LogoModule
