import React, { useEffect, useContext } from "react"
import { Flex, Box } from "@theme-ui/components"
import { Link, navigate, graphql } from "gatsby"
import { useResponsiveValue } from "@theme-ui/match-media"

import { InterfaceContext } from "../context/interface"

import Layout from "../components/layout"
import AssetModule from "../components/asset-module"
import ProgramApplication from "../components/program-application"
import PeopleModule from "../components/people-module"
import TextModule from "../components/text-module"
import OpenPosition from "../components/open-position"
import LogoModule from "../components/logo-module"
import FormWrapper from "../components/form/form-wrapper"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Footer from "../components/footer"
import CTA from "../components/cta"
import Gallery from "../components/gallery"
import InlineMenu from "../components/inline-menu"

const Module = (props) => {
  const { module, padTop, padBottom, prefix, pref, data } = props

  switch (module.__typename) {
    case "ContentfulHero":
      return <Hero key={module.id} data={module} />
    case "ContentfulOpenPosition":
      return (
        <>
          {prefix}
          <OpenPosition
            key={module.id}
            data={module}
            padTop={padTop}
            padBottom={padBottom}
          />
        </>
      )
    case "ContentfulAssetModule":
      return (
        <AssetModule
          key={module.id}
          data={module}
          padTop={padTop}
          padBottom={padBottom}
        />
      )
    case "ContentfulPeopleModule":
      return (
        <>
          {prefix}
          <PeopleModule
            key={module.id}
            data={module}
            padTop={padTop}
            padBottom={padBottom}
          />
        </>
      )
    case "ContentfulNavigationMenu":
      return (
        <>
          <InlineMenu
            key={module.id}
            data={module}
            padTop={padTop}
            padBottom={padBottom}
            prefix={pref}
          />
        </>
      )
    case "ContentfulGallery":
      return (
        <>
          <Gallery
            key={module.id}
            data={module}
            padTop={padTop}
            padBottom={padBottom}
            prefix={pref}
          />
        </>
      )
    case "ContentfulCallToAction":
      return (
        <>
          <CTA
            key={module.id}
            data={module}
            padTop={padTop}
            padBottom={padBottom}
            prefix={pref}
          />
        </>
      )
    case "ContentfulTextModule":
      return (
        <>
          {prefix}
          <TextModule
            key={module.id}
            data={module}
            padTop={padTop}
            padBottom={padBottom}
          />
        </>
      )

    case "ContentfulSpacer":
      const val = module.spacing || 50
      return <Box key={module.id} pb={[val / 2.5, val]} />
    case "ContentfulLogoModule":
      return <LogoModule key={module.id} data={module} />
    case "ContentfulForm":
      return (
        <FormWrapper
          data={module}
          key={module.id}
          padTop={padTop}
          padBottom={padBottom}
          padDescription={true}
        />
      )
    default:
      return null
  }
}

const PageTemplate = ({ data, location, pageContext }) => {
  let { footerNav, pageContent } = data

  const { setTranslationLinks } = useContext(InterfaceContext)

  useEffect(() => {
    setTranslationLinks(pageContext.canonicals)
  }, [])

  let backIndex
  if (pageContent.hideNavigation && pageContent.contentModules) {
    backIndex = pageContent.contentModules.findIndex(
      (c) =>
        c.__typename === "ContentfulOpenPosition" ||
        c.__typename === "ContentfulPeopleModule" ||
        c.__typename === "ContentfulTextModule" ||
        c.__typename === "ContentfulForm"
    )
  }

  const isSmall = useResponsiveValue([true, false])

  useEffect(() => {
    if (!isSmall && !pageContent.hideNavigation) {
      const [htmlTag] = document.getElementsByTagName("html")
      const [bodyTag] = document.getElementsByTagName("body")

      htmlTag.style.overflow = "hidden"
      bodyTag.style.overflow = "hidden"

      return () => {
        htmlTag.style.removeProperty("overflow")
        bodyTag.style.removeProperty("overflow")
      }
    }
  }, [isSmall])

  const [lang] = pageContext.locale.split("-")
  let pref = "/"
  if (lang !== "en") {
    pref = `/${lang}/`
  }

  return (
    <Layout
      prefix={pref}
      nav={data.navigation}
      dropup={data.dropup}
      subNav={data.subnav}
      footerNav={data.footerNavigation}
      shouldShowNav={isSmall || !pageContent.hideNavigation}
      location={location}
      locale={pageContext.locale}
    >
      <SEO
        locale={pageContext.locale}
        canonicals={pageContext.canonicals}
        title={pageContent.title}
      />
      <Box
        sx={{
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <Flex
          py={!pageContent.hideNavigation ? ["50px", "105px"] : undefined}
          sx={{
            flex: "1",
            flexDirection: "column",
            justifyContent: pageContent.centered ? "center" : "flex-start",
            height: pageContent.centered ? "100%" : "auto",
          }}
        >
          {(pageContent.contentModules || []).map((module, index) => {
            const prevModule = pageContent.contentModules[index - 1]
            const nextModule = pageContent.contentModules[index + 1]

            let padTop
            if (prevModule) {
              padTop = prevModule.__typename === "ContentfulHero"
            }

            let padBottom
            if (nextModule) {
              padBottom = nextModule.__typename === "ContentfulHero"
            }

            let prefix
            if (typeof backIndex !== "undefined" && index === backIndex) {
              prefix = (
                <Flex
                  mx="auto"
                  pt="40px"
                  px="40px"
                  sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => navigate(-1)}
                >
                  <Box
                    sx={{
                      height: "10px",
                      img: { transform: "rotate(180deg)", height: "100%" },
                    }}
                    mr={2}
                  >
                    <img src={"/assets/caret.png"} />
                  </Box>

                  <Box
                    sx={{
                      fontSize: "12px",
                      color: "#2A2C3",
                    }}
                  >
                    Back
                  </Box>
                </Flex>
              )
            }

            const moduleIsFullwidth =
              module.__typename == "ContentfulAssetModule" && module.vimeoId
            const padX =
              pageContent.hideNavigation || moduleIsFullwidth
                ? 0
                : ["16px", "45px"]

            return (
              <Box px={padX}>
                <Module
                  module={module}
                  padTop={padTop}
                  padBottom={padBottom}
                  prefix={prefix}
                  pref={pref}
                  data={data}
                />
              </Box>
            )
          })}
          {(isSmall || pageContent.hideNavigation) && (
            <Footer nav={data.footerNavigation} prefix={pref} />
          )}
        </Flex>
      </Box>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageContent($slug: String!, $locale: String!) {
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Subnavigation" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    footerNavigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...footerNavigationFields
    }
    pageContent: contentfulPage(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      title
      centered
      hideNavigation
      contentModules {
        ... on ContentfulHero {
          __typename
          id
          displayTitle
          lightText
          heroContent {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        ... on ContentfulGallery {
          __typename
          id
          title
          tag
          blocks {
            asset {
              gatsbyImageData
            }
            vimeoId
            title
            link {
              __typename
              slug
            }
          }
        }
        ... on ContentfulNavigationMenu {
          __typename
          id
          grid
          items {
            ... on ContentfulNavigationItem {
              ...navigationItemFields
            }
            ... on ContentfulPage {
              title
              slug
            }
          }
        }
        ... on ContentfulCallToAction {
          __typename
          id
          title
          buttonLabel
          link {
            slug
          }
        }
        ... on ContentfulTextModule {
          __typename
          id
          columns {
            id
            enlarge
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          showAsSteps
          internal {
            type
          }
        }
        ... on ContentfulOpenPosition {
          __typename
          id
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          email
          emailSubject
          internal {
            type
          }
        }
        ... on ContentfulForm {
          __typename
          id
          ...formFragment
          contentModules {
            ... on ContentfulTextModule {
              ...textModuleFragment
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          title
          internal {
            type
          }
        }
        ... on ContentfulAssetModule {
          __typename
          id
          cols: columns
          vimeoId
          vimeoThumbnail {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            file {
              url
            }
          }
          assets {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
          internal {
            type
          }
        }
        ... on ContentfulProgramApplication {
          __typename
          id
          heading
          jobTitles
          background {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80)
          }
          body {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulSpacer {
          __typename
          id
          spacing
          internal {
            type
          }
        }
        ... on ContentfulLogoModule {
          __typename
          id
          logos {
            gatsbyImageData(placeholder: BLURRED, width: 80, quality: 80)
          }
          internal {
            type
          }
        }
        ... on ContentfulPeopleModule {
          __typename
          id
          roles {
            ... on ContentfulPerson {
              id
              name
              title
              email
              phone
              images {
                gatsbyImageData(quality: 100)
              }
              description {
                childMarkdownRemark {
                  html
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulRole {
              roleName
              people {
                name
              }
              internal {
                type
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
  }
`
